<template>
    <div class="login-container" style="height: 100%;border-radius: 30px;">
        <a-row type="flex" justify="center" align="center">
            <a-col>
                <a-card>
                    <template #cover>
                        <div style="position: relative;">
                            <img style="padding-left: 1rem;width: 277px;" :src="logo" />
                            <p style="position: absolute;bottom: 0;right: 22px;color: #92AFDB">(Manager)</p>
                        </div>
                    </template>
                    <a-form 
                        :model="formState" 
                        name="login"
                        @finish="onFinish" 
                        @finishFailed="onFinishFailed"
                        layout="vertical"
                    >

                        <a-form-item label="Username" name="username"
                            :rules="[{ required: true, message: 'Please input your username!' }]">
                            <a-input v-model:value="formState.username" />
                        </a-form-item>

                        <a-form-item label="Password" name="password"
                            :rules="[{ required: true, message: 'Please input your password!' }]">
                            <a-input-password v-model:value="formState.password" />
                        </a-form-item>

                        <a-form-item :wrapper-col="{ offset: 0, span: 24 }">
                            <a-button style="width: 100%;" type="primary" html-type="submit">Submit</a-button>
                        </a-form-item>
                    </a-form>
                </a-card>
            </a-col>
        </a-row>
    </div>
</template>

<script>
import AdminService from '../api/AdminService';

export default {
    name: 'Login',
    props: {
        logo: String
    },
    data() {
        return {
            formState: {
                username: '',
                password: ''
            },
        };
    },
    methods: {
        get_profile() {
            AdminService.profile()
                .then(profile => {
                    this.$message.success({ content: 'Login Success!', key: 'login', duration: 1.5 });
                    localStorage.setItem('profile', JSON.stringify(profile));
                    location.reload();
                })
                .catch(error => {
                    this.$message.error({ content: error.toString(), duration: 2 });
                });
        },
        onFinish(values) {
            this.$message.loading({ content: 'Loading...', key: 'login' });
            AdminService.login(values)
                .then(({ token }) => {
                    if (token) {
                        localStorage.setItem('token', token);
                        this.get_profile();
                    } else {
                        this.$message.error({ content: 'Login Failed!', key: 'login', duration: 2 });
                        throw new Error('Login Failed!');
                    }
                })
                .catch(error => {
                    this.$message.error({ content: 'Login Failed!', key: 'login', duration: 2 });
                    this.$message.error({ content: error.toString(), duration: 2 });
                });
        },
        clearForm() {
            this.formState.username = '';
            this.formState.password = '';
        },
        onFinishFailed(errorInfo) {
            console.log('Failed:', errorInfo);
        },
    },
    mounted() {
        if (localStorage.getItem('token')) {
            this.$router.push('/');
        }
    }
}
</script>

<style scope lang="scss">
    .login-container {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #E7EDF7;
    }
</style>